<script setup lang="ts">
import debounce from 'debounce';
import { useFocus } from '@vueuse/core';
import { editGoogleImageResolution } from '@/helpers/googleImageResolutions';
const props = defineProps<{
	search?: string
	type?: 'title' | 'author' | 'publisher' | 'isbn'
	mode: 'open' | 'toggle'
	infiniteScroll?: boolean
	itemCount: number
	loadingMore?: boolean
	responseCount?: number
	loadResultsOnMount?: boolean
}>();

const emit = defineEmits<{
	(event: 'itemChange', items: Array<any>): void
	(event: 'searchChange', search: string): void
	(event: 'update:loadingMore', value: boolean): void
	(event: 'update:responseCount', value: number): void
	(event: 'closeSearch'): void
	(event: 'blur'): void
}>();

const { $toast } = useNuxtApp();

const { t } = useI18n();

const queryStorage = useQueryStorage();

const loading = ref(false);
const displayedBooks = ref<Array<IGoogleBook>>([]);
const searchTerm = ref(props.search || queryStorage.value.search || '');
const searchBarRef = ref();
const { focused: searchBarFocus } = useFocus(searchBarRef);
const bookType = ref(props.type || queryStorage.value.type || null);
const active = ref(props.mode === 'open');
const blockSearch = ref(false);
const coreSearch: Ref<HTMLElement | null> = ref(null);
const currentIndex = ref(0);
let mousedownHappened = false;

const handleOutsideClick = (event: MouseEvent) => {
	if (coreSearch.value && !coreSearch.value.contains(event.target as Node))
		active.value = false;

	mousedownHappened = true;
};

const handleFocusOut = () => {
	setTimeout(() => {
		if (coreSearch.value && !coreSearch.value.contains(document.activeElement as Node) && !mousedownHappened)
			active.value = false;

		mousedownHappened = false;
	}, 0);
};

const updateQueryStorage = (googleBooks?: Array<any>) => {
	const obj = {
		search: searchTerm.value,
		type: bookType.value,
		googleBooks: googleBooks || queryStorage.value.googleBooks,
	};
	queryStorage.value = obj;
};

const onSearch = async (reset: boolean) => {
	updateQueryStorage();

	if (reset || !props.infiniteScroll) {
		blockSearch.value = false;
		currentIndex.value = 0;
		updateQueryStorage([]);
	}

	if (blockSearch.value)
		return;

	const searchQuery = searchTerm.value?.toLowerCase()?.trim();

	let items: Array<IGoogleBook> = [];
	if (searchQuery && searchQuery.length >= 2) {
		try {
			loading.value = true;
			emit('update:loadingMore', true);
			const index = currentIndex.value;

			if (props.infiniteScroll)
				currentIndex.value += props.itemCount;

			let search = searchQuery;
			switch (bookType.value) {
				case 'title':
					search = `intitle:${search}`;
					break;
				case 'author':
					search = `inauthor:${search}`;
					break;
				case 'publisher':
					search = `inpublisher:${search}`;
					break;
				case 'isbn':
					search = `isbn:${search}`;
					break;
			}

			const params = new URLSearchParams({
				q: search,
				maxResults: props.itemCount.toString(),
				startIndex: index.toString(),
			});

			const response = await fetch(`https://www.googleapis.com/books/v1/volumes/?${params}`);
			if (response.status === 429) {
				$toast.error(t('toast.searchTooManyRequests'), { toastId: 'searchTooManyRequests' });
				return;
			}
			if (response.ok) {
				const { items: bookItems } = await response.json();
				items = (bookItems ?? [])
					.map((item) => {
						return {
							title: item.volumeInfo.title,
							image: item.volumeInfo.imageLinks?.smallThumbnail,
							published: item.volumeInfo.publishedDate,
							authors: item.volumeInfo.authors,
							googleBookId: item.id,
						};
					});
				if (items.length === 0)
					blockSearch.value = true;

				// when in the navbar, we store the results and we will show them later on
				if (!props.infiniteScroll)
					updateQueryStorage(items);
			}
			else {
				$toast.error('Error while fetching data from Google Books API.');
				return;
			}
		}
		finally {
			loading.value = false;
			emit('update:loadingMore', false);
			emit('update:responseCount', items.length);
		}
	}

	if (reset)
		displayedBooks.value = [];

	if (props.infiniteScroll)
		displayedBooks.value.push(...items);
	else
		displayedBooks.value = items;

	emit('itemChange', displayedBooks.value);
	emit('searchChange', searchTerm.value);
};

watch(() => active, (value) => {
	if (value && searchTerm.value) {
		// when the search is in the navbar and the search terms are equal -> show the stored results
		if (!props.infiniteScroll && queryStorage.value.googleBooks && searchTerm.value === queryStorage.value.search)
			displayedBooks.value = queryStorage.value.googleBooks;
		else
			onSearch(false);
	}
}, { deep: true });

const handleScroll = () => {
	const bottomOfWindow = Math.ceil(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) >= document.documentElement.offsetHeight;

	if (bottomOfWindow)
		onSearch(false);
};

onMounted(() => {
	if (props.mode === 'toggle') {
		document.addEventListener('mousedown', handleOutsideClick);
		coreSearch.value?.addEventListener('focusout', handleFocusOut);
	}

	if (props.loadResultsOnMount && searchTerm.value)
		onSearch(false);

	if (props.infiniteScroll)
		window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	if (props.mode === 'toggle') {
		document.removeEventListener('mousedown', handleOutsideClick);
		document.removeEventListener('scroll', handleScroll);
		coreSearch.value?.removeEventListener('focusout', handleFocusOut);
	}
});

const resetResults = () => {
	displayedBooks.value = [];
	emit('itemChange', displayedBooks.value);
	onSearch(true);
};

const debounceSearch = debounce(() => resetResults(), 1000);

const navigateToSearchPage = () => {
	updateQueryStorage();
	return navigateTo({ name: 'app.search' });
};

const handleEnterKey = (event: KeyboardEvent) => {
	if (event.key === 'Enter') {
		searchBarFocus.value = false;
		navigateToSearchPage();
	}
};

const closeSearch = () => emit('closeSearch');

export interface IGoogleBook {
	title: string
	image: string
	published: string
	authors: string[]
	googleBookId: string
}

const externalToggle = (newState: boolean) => active.value = newState;
defineExpose({ externalToggle });
</script>

<template>
	<div class="core-search-wrapper" :class="{ 'core-search-wrapper--active': active }" ref="coreSearch">
		<div class="core-search">
			<div class="core-search__button-wrapper">
				<button
					type="button"
					class="core-search__button"
					:tabindex="!active ? -1 : undefined"
					@click="navigateToSearchPage"
				>
					<Icon name="search" />
				</button>
			</div>
			<input
				autocomplete="off"
				type="search"
				class="core-search__input"
				:placeholder="$t('search.core.placeholder')"
				ref="searchBarRef"
				v-model="searchTerm"
				@focus="active = true"
				@input="debounceSearch"
				@keydown.enter="handleEnterKey"
			/>
		</div>
		<div class="core-search-dropdown">
			<div class="core-search-dropdown__header">
				<div class="core-search-dropdown__title-container">
					<span class="core-search-dropdown__title">{{ $t('search.core.title') }}</span>
					<span class="core-search-dropdown__subtitle">
						<span>{{ $t('props.poweredBy') }}:</span>
						<NuxtLink to="https://books.google.com/" target="_blank">
							<GoogleBooksLogo />
							<span class="aria:sr">Google Books</span>
						</NuxtLink>
					</span>
				</div>
				<FormCheckGroup row>
					<FormCheck
						type="radio"
						name="search"
						:label="$t('props.title')"
						pill
						icon="text"
						value="title"
						id="coreSearchTitle"
						v-model="bookType"
						@change="resetResults"
					/>
					<FormCheck
						type="radio"
						name="search"
						:label="$t('props.author')"
						pill
						icon="user"
						value="author"
						id="coreSearchAuthor"
						v-model="bookType"
						@change="resetResults"
					/>
					<FormCheck
						type="radio"
						name="search"
						:label="$t('props.publisher')"
						pill
						icon="megaphone"
						value="publisher"
						id="coreSearchPublisher"
						v-model="bookType"
						@change="resetResults"
					/>
					<FormCheck
						type="radio"
						name="search"
						:label="$t('props.isbn')"
						pill
						icon="db"
						value="isbn"
						id="coreSearchIsbn"
						v-model="bookType"
						@change="resetResults"
					/>
				</FormCheckGroup>
			</div>
			<div class="core-search-dropdown__body" v-if="props.mode === 'toggle' && searchTerm">
				<span class="core-search-dropdown__title" v-if="displayedBooks.length > 0 || loading">{{ $t('search.core.results') }}</span>

				<div class="core-search-dropdown__list" v-if="loading">
					<Skeleton size="100% 7.2rem" v-for="i in 5" :key="i" />
				</div>

				<ul class="core-search-dropdown__list" v-else>
					<li class="core-search-dropdown__item" v-for="book in displayedBooks" :key="book.googleBookId">
						<NuxtLink class="core-search-dropdown__link" :to="`/book/${encodeURIComponent(book.googleBookId)}`" @click="() => { updateQueryStorage(); active = false; closeSearch(); }">
							<span class="core-search-dropdown__link-image-wrapper">
								<img class="core-search-dropdown__link-image" :src="editGoogleImageResolution(book.image, 'quality', 'core-search', 'list')" v-if="book.image" />
								<div class="core-search-dropdown__link-image-placeholder" v-else>
									<Icon name="google-media-image" />
								</div>
							</span>
							<span class="core-search-dropdown__link-content">
								<span class="core-search-dropdown__link-title">{{ book.title }}</span>
								<span class="core-search-dropdown__link-author">{{ (book.authors || []).join(', ') }}</span>
							</span>
						</NuxtLink>
					</li>
				</ul>
			</div>
			<button
				type="button"
				class="core-search-dropdown__footer"
				v-if="mode === 'toggle' && searchTerm"
				@click="navigateToSearchPage"
			>
				<span>{{ $t('search.core.allResults') }} "{{ searchTerm }}"</span>
			</button>
		</div>
	</div>
</template>
